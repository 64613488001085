import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import Input from './components/Input';
import ToDo from './components/ToDo';
import { ReactComponent as CompanyLogo } from './svg/logo_white.svg';

function App() {
  const [toDos, setToDos] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  const fetchToDos = () =>
    fetch('https://jsonplaceholder.typicode.com/todos')
      .then((response) => response.json())
      .then((json) => {
        setToDos(json);
      });

  useEffect(() => {
    fetchToDos();
  }, []);

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSearch = () => {
    if (!searchInput.length) {
      fetchToDos();
      return;
    }
    fetch(`https://jsonplaceholder.typicode.com/todos?title=${searchInput}`)
      .then((response) => response.json())
      .then((json) => {
        setToDos(json);
        console.log(json);
      })
      .then(() => toast(`Searched for ${searchInput}`));
  };

  return (
    <AppContainer>
      <Header>
        <CompanyLogo />
        <SearchContainer>
          <Input
            name='search'
            value={searchInput}
            label='Type here'
            onChange={handleSearchInput}
          />
          <SearchButton onClick={handleSearch}>Search</SearchButton>
        </SearchContainer>
      </Header>
      <SubHeader>
        <ContainerMax>{`Todos [${toDos.length}]`}</ContainerMax>
      </SubHeader>
      <ContainerMax>
        <ToDo toDos={toDos} setToDos={setToDos} />
        <ToastContainer
          position='bottom-left'
          pauseOnHover
          closeOnClick
          hideProgressBar
          newestOnTop
        />
      </ContainerMax>
    </AppContainer>
  );
}
const ContainerMax = styled.div`
  width: 100%;
  max-width: 560px;
  margin: 0 20px;
`;
const AppContainer = styled.div`
  font-family: 'DM Sans', sans-serif;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: hidden;
`;
const Header = styled.header`
  width: 100%;
  height: 129px;
  background: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 64px;
`;
const SearchContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
const SearchButton = styled.button`
  background: none;
  border: 1px solid #e0e0e0;
  padding: 16px 24px;
  min-width: 182px;
  font-size: 1rem;
  line-height: 1;
  color: #e0e0e0;
  cursor: pointer;
  margin-left: 28px;
  margin-bottom: 24px;
`;
const SubHeader = styled.div`
  background: #a0a3aa;
  width: 100%;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20;
  line-height: 1;
  font-weight: 700;
`;

export default App;
