import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Input from './Input';
import ToDoListItem from './ToDoListItem';

const ToDo = ({ toDos, setToDos }) => {
  const [newToDo, setNewToDo] = useState('');

  const handleAddToDoInput = (e) => {
    setNewToDo(e.target.value);
  };
  const handleSaveTodo = () => {
    if (!newToDo.length) {
      return;
    }

    fetch('https://jsonplaceholder.typicode.com/todos', {
      method: 'POST',
      body: JSON.stringify({
        userId: 11,
        title: newToDo,
        completed: false,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setToDos([...toDos, json]);
        setNewToDo('');
      })
      .then(() => toast('new todo added'));
  };

  return (
    <Container>
      <ToDoMenu>
        <Input
          name='new todo'
          label='Enter to do here'
          value={newToDo}
          onChange={handleAddToDoInput}
        />
        <AddButton onClick={handleSaveTodo}>Add</AddButton>
      </ToDoMenu>
      {!toDos.length ? (
        <ToDosNote>no todos</ToDosNote>
      ) : (
        <ToDoList>
          {toDos.map(({ completed, id, title }) => (
            <ToDoListItem
              key={id}
              value={title}
              completed={completed}
              id={id}
              toDos={toDos}
              setToDos={setToDos}
            />
          ))}
        </ToDoList>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const ToDoMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 8px 0px 12px;
`;
const AddButton = styled.button`
  background: none;
  border: 1px solid black;
  padding: 16px 24px;
  margin-bottom: 24px;
  margin-left: 30px;
  width: 150px;
  height: 48px;
  font-size: 1rem;
  line-height: 1;
  color: black;
  cursor: pointer;
`;
const ToDoList = styled.ul``;
const ToDosNote = styled.p`
  text-align: center;
  color: #a0a3aa;
`;

export default ToDo;
