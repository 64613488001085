import React, { useState, useCallback, ChangeEvent } from 'react';
import styled from 'styled-components';

interface IInput {
  label: string;
  name: string;
  value: string | '';
  required?: boolean;
  help?: string;
  error?: string;
  disabled?: boolean;
  onChange: (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => void;
}

const Input: React.FunctionComponent<IInput> = ({
  label,
  name,
  value,
  required,
  help,
  error,
  disabled,
  onChange,
  ...rest
}): React.ReactElement => {
  const [focused, setFocused] = useState(false);
  const onFocus = useCallback(() => setFocused(true), []);
  const onBlur = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) =>
      !e.target.value && setFocused(false),
    []
  );
  const errorBoolean = !!error;
  return (
    <Label {...rest}>
      <FloatingLabel
        focused={focused}
        required={required}
        error={!!error}
        disabled={disabled}
      >
        {label}
      </FloatingLabel>
      <StyledInput
        placeholder={label}
        name={name}
        value={value}
        focused={focused}
        error={errorBoolean}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
      />
      <UnderText error={!!error}>{error || help}</UnderText>
    </Label>
  );
};

const Label = styled.label`
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: text;
  width: 100%;
`;
const FloatingLabel = styled.div<{
  focused: boolean;
  required?: boolean;
  error: boolean;
  disabled?: boolean;
}>`
  position: relative;
  top: 1.825rem;
  user-select: none;
  font-size: 1.25rem;
  min-height: 1.625rem;
  color: ${({ disabled }) => (disabled ? '#CFD1D4' : '#777B85')};
  transform: translateY(0px) scale(1);
  transform-origin: left;
  transition: transform 500ms;

  ${({ focused }) =>
    focused &&
    `
    color: #A0A3AA;
    transform: translateY(-1.4rem) scale(0.7);
    `}

  ${({ required, error }) =>
    required &&
    `
    &:before {
      content: '*';
      color: ${error ? '#EF3A50' : '#2CC7B0'}
      padding-right: 0.5rem;
    }
  `}
`;
const StyledInput = styled.input<{ focused: boolean; error: boolean }>`
  font-size: 1.25rem;
  line-height: 1.5;
  padding-left: 0.1875rem;
  border: none;
  background: none;
  border-bottom: 0.0625rem solid
    ${({ error }) => (error ? '#EF3A50' : '#A0A3AA')};

  ${({ focused, error }) =>
    focused &&
    `
    border-bottom: 0.0625rem solid ${error ? '#EF3A50' : '#A0A3AA'};
    outline: 0;
    box-shadow: 0;
  `}

  &::placeholder {
    color: transparent;
  }
`;
const UnderText = styled.span<{ error: boolean }>`
  box-sizing: border-box;
  color: ${({ error }) => (error ? '#EF3A50' : '#A0A3AA')};
  font-size: 1rem;
  line-height: 1.125rem;
  padding: 0.1875rem 0rem;
  min-height: 1.5rem;
`;

export default Input;
