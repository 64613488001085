import React from 'react';
import styled from 'styled-components';

const DeleteNotificatino = ({ title, handleDelete, handleCancel }) => {
  return (
    <Overlay>
      <Box>
        <Question>Do you want to delete the following todo:</Question>
        <Title>{title}</Title>
        <ButtonWrap>
          <Button onClick={handleCancel}>Go Back</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </ButtonWrap>
      </Box>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
const Box = styled.div`
  padding: 51px 66px 84px;
  margin: 10px;
  width: 100%;
  max-width: 560px;
  background: white;
  opacity: 1;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Question = styled.div`
  color: black;
  padding-bottom: 20px;
`;
const Title = styled.div`
  color: #777b85;
`;
const ButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 90px;
`;
const Button = styled.button`
  background: none;
  border: 1px solid black;
  padding: 16px 24px;
  min-width: 182px;
  font-size: 1rem;
  line-height: 1;
  color: black;
  cursor: pointer;
`;

export default DeleteNotificatino;
