import React from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';

interface ICheckmark {
  checked: boolean;
  width?: number;
  height?: number;
}

const Checkmark: React.FunctionComponent<ICheckmark> = ({
  checked,
  width,
  height,
}): React.ReactElement => {
  const pathLength = useMotionValue(0);
  const opacity = useTransform(pathLength, [0.05, 0.15], [0, 1]);
  return (
    <motion.svg
      initial={false}
      animate={checked ? 'checked' : 'unchecked'}
      viewBox='0 0 18 13'
      width={width}
      height={height}
    >
      <motion.path
        d='
          M 0 4.5
          L 5 9
          L 14 0'
        transform='translate(2 2)'
        fill='none'
        stroke='white'
        strokeWidth='2'
        variants={tickVariants}
        style={{ pathLength, opacity }}
        custom={checked}
      />
    </motion.svg>
  );
};

const tickVariants = {
  checked: {
    pathLength: 1,
    transition: {
      delay: 0.2,
      duration: 0.2,
    },
  },
  unchecked: {
    pathLength: 0,
    transition: {
      duration: 0.2,
    },
  },
};

export default Checkmark;
