import React, { useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as EditIcon } from '../svg/edit.svg';
import { ReactComponent as DeleteIcon } from '../svg/trash.svg';
import { ReactComponent as EnterIcon } from '../svg/enter.svg';
import Checkbox from './Checkbox/Checkbox';
import Input from './Input';
import DeleteNotificatino from './DeleteNotificatino';
import { toast } from 'react-toastify';

const ToDoListItem = ({ value, completed, id, toDos, setToDos }) => {
  const [isEdited, setIsEdited] = useState(false);
  const [title, setTitle] = useState('');
  const [isDeleteNote, setIsDeleteNote] = useState(false);

  const handleEdit = () => {
    setTitle(value);
    setIsEdited(true);
  };
  const handleInput = (e) => {
    setTitle(e.target.value);
  };
  const handleSave = () => {
    fetch(`https://jsonplaceholder.typicode.com/todos/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        title,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setToDos(
          toDos.map((todo) =>
            todo.id === json.id ? { ...todo, title: json.title } : todo
          )
        );
      })
      .then(() => {
        setIsEdited(false);
        setTitle('');
        toast('edited todo saved');
      });
  };

  const handleCheckbox = () => {
    fetch(`https://jsonplaceholder.typicode.com/todos/${id}`, {
      method: 'PUT',
      body: JSON.stringify({
        completed: !completed,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setToDos(
          toDos.map((todo) =>
            todo.id === json.id ? { ...todo, completed: json.completed } : todo
          )
        );
      });
  };

  const handleDeleteClick = () => {
    setIsDeleteNote(true);
  };

  const handleDelete = () => {
    fetch(`https://jsonplaceholder.typicode.com/todos/${id}`, {
      method: 'DELETE',
    })
      .then(() => {
        setToDos(toDos.filter((todo) => todo.id !== id));
      })
      .then(() => {
        setIsDeleteNote(false);
        toast('todo deleted');
      });
  };

  return (
    <>
      {isDeleteNote && (
        <DeleteNotificatino
          title={value}
          handleDelete={handleDelete}
          handleCancel={() => setIsDeleteNote(false)}
        />
      )}
      <Item>
        <Checkbox
          name={`todo-${id}-checkbox`}
          checked={completed}
          onChange={handleCheckbox}
        />
        <Content>
          {isEdited ? (
            <Input
              name={`todo-${id}-input`}
              value={title}
              label=''
              onChange={handleInput}
            />
          ) : (
            value
          )}
        </Content>
        {isEdited ? (
          <ItemButton onClick={handleSave}>
            <EnterIcon />
          </ItemButton>
        ) : (
          <ItemButton onClick={handleEdit}>
            <EditIcon />
          </ItemButton>
        )}
        <ItemButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </ItemButton>
      </Item>
    </>
  );
};

const Item = styled.li`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 22px;
  &:nth-of-type(odd) {
    background: #f1f2f3;
  }
`;
const Content = styled.div`
  width: 100%;
  padding-left: 51px;
`;
const ItemButton = styled.button`
  background: none;
  border: none;
  padding: 15px;
  font-size: 1rem;
  line-height: 1;
  color: black;
  cursor: pointer;
`;
export default ToDoListItem;
