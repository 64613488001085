import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import CheckmarkVariants from './CheckmarkVariants';

type Variant = 'tiny' | 'small' | 'medium' | 'large';

interface ICheckbox {
  variant?: Variant;
  name: string;
  checked: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: boolean;
  radio?: boolean;
}

interface CheckboxVariant {
  checkboxSize: string;
}

interface CheckboxVariants {
  [k: string]: CheckboxVariant;
}

const Checkbox: React.FunctionComponent<ICheckbox> = ({
  variant = 'medium',
  name,
  checked,
  onChange,
  error,
  radio,
  children,
  ...rest
}): React.ReactElement => {
  return (
    <div>
      <StyledLabel whileTap='tap'>
        <HiddenCheckbox
          type={!radio ? 'checkbox' : 'radio'}
          name={name}
          checked={checked}
          onChange={onChange}
          variant={variant}
          {...rest}
        />
        <VisibleCheckbox
          variants={animateCheckbox}
          variant={variant}
          error={error}
          radio={radio}
        >
          <CheckmarkVariants variant={variant} checked={checked} />
        </VisibleCheckbox>
        {children}
      </StyledLabel>
    </div>
  );
};

const variants: CheckboxVariants = {
  tiny: {
    checkboxSize: '0.625rem',
  },
  small: {
    checkboxSize: '1rem',
  },
  medium: {
    checkboxSize: '1.5rem',
  },
  large: {
    checkboxSize: '2rem',
  },
};

const animateCheckbox = {
  tap: {
    scale: 0.95,
  },
};

const StyledLabel = styled(motion.label)`
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  user-select: none;
  /* width: 100%; */
`;
const VisibleCheckbox = styled(motion.div)<{
  variant: Variant;
  error?: boolean;
  radio?: boolean;
}>`
  ${({ radio }) =>
    radio &&
    `
    border-radius: 50%;
  `}
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: ${({ variant }) => variants[variant].checkboxSize};
  width: ${({ variant }) => variants[variant].checkboxSize};
  min-width: ${({ variant }) => variants[variant].checkboxSize};
  background-color: #ffffff;
  border: 0.0625rem solid ${({ error }) => (error ? '#EF3A50' : '#CFD1D4')};
`;
const HiddenCheckbox = styled.input<{ variant?: Variant }>`
  appearance: none;
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  cursor: pointer;
  &:checked + ${VisibleCheckbox} {
    background: #2cc7b0;
    border-color: #2cc7b0;
  }
`;

export default Checkbox;
