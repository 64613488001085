import React from 'react';
import Checkmark from './Checkmark';

type Variant = 'tiny' | 'small' | 'medium' | 'large';

interface ICheckmarkVariants {
  variant?: Variant;
  checked: boolean;
}

interface CheckmarkVariant {
  width: number;
  height: number;
}

interface CheckmarkVariants {
  [k: string]: CheckmarkVariant;
}

const CheckmarkVariants: React.FunctionComponent<ICheckmarkVariants> = ({
  variant = 'medium',
  checked,
}) => {
  const variants: CheckmarkVariants = {
    tiny: {
      width: 8,
      height: 5,
    },
    small: {
      width: 10,
      height: 7,
    },
    medium: {
      width: 16,
      height: 12,
    },
    large: {
      width: 18,
      height: 13,
    },
  };

  return (
    <Checkmark
      checked={checked}
      width={variants[variant].width}
      height={variants[variant].height}
    />
  );
};

export default CheckmarkVariants;
